import runtimeEnv from '@mars/heroku-js-runtime-env'
import NLUtils from '../utils/utils'

class SafetyNetworkService {
  constructor (token) {
    const env = runtimeEnv()
    this.endpoint = env.REACT_APP_ACCOUNT_SERVICES_URL
    this.token = token
  }

  async fetchPendingPermissions () {
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`get`, `profile/networks/pending-permissions`)
  }

  async updatePendingPermissions (permissionId, status) {
    const body = JSON.stringify({ status })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`put`, `profile/networks/pending-permissions/${permissionId}`, body)
  }

  async approvePendingPermission (permissionId) {
    return this.updatePendingPermissions(permissionId, 'accept')
  }
  async rejectPendingPermission (permissionId) {
    return this.updatePendingPermissions(permissionId, 'reject')
  }

  async fetchSafetyNetworkOwners () {
    const response = await NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`get`, `profile/networks/permissions`)
    let members = await response.json()

    if (!members) {
      return []
    }

    return members
  }
  async fetchSafetyNetworkMembers () {
    const response = await NLUtils.oAuthfetchRequest(this.endpoint, this.token)('post', 'profile/networks', null)
    let members = await response.json()
    members = members.shift()

    if (!members) {
      members = {
        members: [],
        owner: {},
        invitations: []
      }
    }

    return members
  }

  async inviteMember (name, phone, networkId) {
    const body = JSON.stringify({ name, phone })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`post`, `profile/networks/${networkId}/invitation`, body)
  }

  async requestLocationPermission (phone, networkId) {
    const body = JSON.stringify({ phone, permission: 'read:location' })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`post`, `profile/networks/request-permission`, body)
  }

  async sendLocationPermission (phone, networkId) {
    const body = JSON.stringify({ phone })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`post`, `profile/networks/send-location`, body)
  }
  async stopSharingLocation (phone) {
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`delete`, `profile/networks/permissions/${phone}?scope=read:location`)
  }

  async getLocationUrl (phone) {
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`get`, `profile/networks/${phone}/location`)
  }

  async removeMember (phone, networkId) {
    const body = JSON.stringify({ phone })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`delete`, `profile/networks/${networkId}/member`, body)
  }

  async cancelInvitation (phone, networkId) {
    const body = JSON.stringify({ phone })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`delete`, `profile/networks/${networkId}/invitation`, body)
  }
}

export default SafetyNetworkService
