import { FeatureFlags } from './featureFlags'

class Event {
  constructor (category, action, label = null, shouldMatchLabel = false) {
    this.category = category
    this.action = action
    this.label = label
    this.shouldMatchLabel = shouldMatchLabel
  }

  getAssociatedFeatureFlags () {
    // try to match the most broad version first (note: this will only try to match the broad version if the 'shouldMatchLabel' flag is false. Otherwise, it will just try to match w/ label twice.
    const defaultMatch = flagEventMap[this.keyed()]
    if (defaultMatch) {
      return defaultMatch
    }
    // if that doesn't find anything, try to match the more specific version
    const forcedCheck = flagEventMap[this.keyed(true)]
    return forcedCheck
  }

  getFeatureActiveFeatureFlags () {
    const featureFlags = this.getAssociatedFeatureFlags()
    if (featureFlags) {
      let activeFlags = []
      featureFlags.forEach((featureFlag) => {
        try {
          const isEnabled = window.optimizelyClientInstance.isFeatureEnabled(featureFlag)
          if (isEnabled) {
            activeFlags.push(featureFlag)
          }
        } catch (e) {
          console.log(e)
        }
      })
      if (activeFlags && activeFlags.length > 0) {
        return activeFlags.join(', ')
      }
    } else {
      return null
    }
    return null
  }

  keyed (forceMatchLabel = false) {
    let key = `${this.category}-${this.action}`
    if (this.shouldMatchLabel || forceMatchLabel) {
      key += `${this.label}`
    }
    return key
  }

  track (label) {
    let trackedLabel = this.label
    if (label) {
      trackedLabel = label
    }
    snowplow.track(this.category, this.action, trackedLabel)
  }
}

// convert analytics event strings to compile time checked (and centralize in 1 spot)
const analyticsEvents = {
  sendSafetyNetworkInvite: new Event('networks_invite', 'send'),
  openAddFriendDialog: new Event('dialog', 'open', 'add_friend', true)
}

const analyticsCategories = {
  NETWORK_ERROR: 'network_error',
  SAFETY_NETWORK: 'safety_network'
}

// maps analytics events to their respective events
let flagEventMap = {}
flagEventMap[analyticsEvents.openAddFriendDialog.keyed()] = [FeatureFlags.noAppRequired, FeatureFlags.addFriendCta, FeatureFlags.hideAddFriendCopy]
flagEventMap[analyticsEvents.sendSafetyNetworkInvite.keyed()] = [FeatureFlags.noAppRequired, FeatureFlags.addFriendCta, FeatureFlags.hideAddFriendCopy]

let snowplow = {
  categories: analyticsCategories,
  events: analyticsEvents,
  setUserId: (user_id) => {
    window.snowplow('setUserId', user_id)
  },
  track: (category, action, label) => {
    const event = new Event(category, action, label)
    const activeFeatureFlags = event.getFeatureActiveFeatureFlags()
    let property = null
    if (activeFeatureFlags) {
      property = activeFeatureFlags
    }
    window.snowplow('trackStructEvent', category, action, label, property)
  }

}

export let Analytics = snowplow
export let AnalyticsEvent = Event
