/* eslint-disable camelcase */
import runtimeEnv from '@mars/heroku-js-runtime-env'
import NLUtils from '../utils/utils'

class HouseholdApiService {
  constructor (token) {
    const env = runtimeEnv()
    this.endpoint = env.REACT_APP_ACCOUNT_SERVICES_URL
    this.token = token
  }

  async fetchHousehold () {
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`post`, `profile/households`, null)
  }

  async cancelInvitation (phone, household_id) {
    const body = JSON.stringify({ phone })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`delete`, `profile/households/${household_id}/invitation`, body)
  }

  async inviteMember (phone, name, household_id) {
    const body = JSON.stringify({ phone, name })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`post`, `profile/households/${household_id}/invitation`, body)
  }

  async removeMember (phone, household_id) {
    const body = JSON.stringify({ phone })
    return NLUtils.oAuthfetchRequest(this.endpoint, this.token)(`delete`, `profile/households/${household_id}/member`, body)
  }
}

export default HouseholdApiService
