import React, { Component, Fragment } from 'react'
import { instanceOf } from 'prop-types'
import './styles/App.css'
import Grid from '@material-ui/core/Grid'
import Header from './components/header'
import Household from './pages/household'
import SafetyNetwork from './pages/safetyNetwork'
import ChangePin from './pages/changePin'
import { withCookies, Cookies } from 'react-cookie'
import ConfirmInvite from './pages/confirmInvite'
import {
  MuiThemeProvider,
  createMuiTheme,
  darken
} from '@material-ui/core/styles'
import { Route, Switch, Redirect } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'
import AuthService from './services/authService'
import { CircularProgress } from '@material-ui/core'
import LogoutPage from './pages/logout'
import LoginPage from './pages/login'
import SchedulerPage from './pages/scheduler'
import NavigationMenu from './components/navigationMenu'
import { Analytics } from './utils/analytics'

import {
  createInstance,
  OptimizelyProvider
} from '@optimizely/react-sdk'
import { UserInfo } from './utils/userInfo'
import NLUtils from './utils/utils'

const optimizelyClientInstance = createInstance({
  sdkKey: NLUtils.isProdEnv() ? 'CVRgNLJGo5yizbuqCo4yEe' : '2H5W1a7qDXYnCHGVgjrrqL',
  datafileOptions: {
    autoUpdate: true,
    updateInterval: NLUtils.isProdEnv() ? 600000 : 30000
  }
})
window.optimizelyClientInstance = optimizelyClientInstance

const customPalette = {
  primary: {
    light: '#448bfb',
    main: '#166efb',
    dark: '#0f4daf',
    contrastText: '#fff'
  },
  secondary: {
    main: '#ff6a61',
    contrastText: '#fff'
  },
  default: {
    main: '#344351'
  },
  error: {
    main: '#ff6a61'
  },
  background: {
    default: '#F0F3F7'
  }
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      // The default props to change
      disableRipple: true // No more ripple, on the whole application 💣!

    }
  },
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: '#F5F5F5',
        color: '#344351'
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFFFFF'
      }
    },
    MuiListSubheader: {
      sticky: {
        backgroundColor: '#FFFFFF'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#9CA3AD',
        '&.Mui-focused': {
          color: '#9CA3AD'
        }

      }
    },
    MuiListItemText: {
      secondary: {
        color: '#344351'
      }
    },
    MuiInputBase: {
      root: {
        color: '#344351'
      }
    },
    MuiInputAdornment: {
      root: {
        color: '#9CA3AD'
      }
    },
    MuiPaper: {
      root: {
        // color: '#344351'
      },
      rounded: {
        borderRadius: 5
      },
      elevation1: {
        boxShadow: '0 3px 3px rgba(52, 67, 81, 0.05)'
      }
    },
    MuiButton: { // Name of the component ⚛️ / style sheet
      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: darken(customPalette.primary.main, 0.2)
        }
      },

      root: {
        fontWeight: 'normal',
        color: customPalette.default.main
      },
      text: {
        textTransform: 'none'
      }
    }
  },
  palette: customPalette
})

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  }

  constructor (props) {
    super(props)

    const { cookies } = props
    const authService = new AuthService(cookies)
    this.authService = authService

    this.state = {
      isAuthenticating: false
    }

    if (authService.hasUserId()) {
      Analytics.setUserId(authService.getUserId())
    }
  }

  async updateAuth () {
    const { props } = this
    const { cookies } = props

    const authService = this.authService
    const queryParams = new URLSearchParams(window.location.search)
    const newToken = queryParams.get('token')
    const state = queryParams.get('state')

    if (authService.shouldRefreshToken()) {
      this.setState({
        isAuthenticating: true
      })
      await authService.reauthenticateAndUpdateCredentials()
      this.setState({
        isAuthenticating: false
      })
    } else if (!authService.hasToken() && newToken) {
      authService.digestOAuthRedirectParams(queryParams)
    }

    if (state) {
      const stateValue = cookies.get(state)
      if (stateValue) {
        let redirectPath = stateValue.path
        let redirectSearch = stateValue.search
        cookies.remove(state)
        window.location.href = redirectPath + redirectSearch
      }
    }

    if (authService.hasToken() && !authService.hasUserId()) {
      try {
        const userInfo = await authService.getUserInfo()
        const userInfoJson = await userInfo.json()
        const { _id } = userInfoJson
        if (_id) {
          authService.setUserId(_id)
          Analytics.setUserId(_id)
        }
      } catch (e) {

      }
    }
  }

  async componentWillMount () {
    await this.updateAuth()
  }

    Loading = () => {
      return (
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <CircularProgress />
        </Paper>
      )
    }

    PrivateRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={props =>
            !this.authService.hasToken() ? (
              <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
              }} />
            ) : (
              <Component {...props} />
            )
          }
        />
      )
    }

  SafetyNetwork = ({ location }) => {
    const { props } = this
    const { cookies } = props
    const token = cookies.get('token')

    return (
      <Fragment>
        <SafetyNetwork token={token} location={location} cookies={cookies} />
      </Fragment>
    )
  }

  ChangePin = () => {
    const { props } = this
    const { cookies } = props
    const token = cookies.get('token')

    return (
      <Fragment>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ChangePin token={token} />
        </Grid>
      </Fragment>
    )
  }

  Household = ({ location }) => {
    const { props } = this
    const { cookies } = props
    const token = cookies.get('token')

    return (
      <Fragment>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Household token={token} location={location} cookies={cookies} />
        </Grid>
      </Fragment>
    )
  }

  SafetyNetworkInvite = ({ location }) => {
    const { props } = this
    const { cookies } = props
    const token = cookies.get('token')

    let params = new URLSearchParams(location.search)
    let invite = params.get('invite')

    return (
      <Fragment>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          {invite ? (
            <ConfirmInvite token={token} inviteId={invite} type={'safety network'} />
          ) : (
            <Paper style={{ padding: 20, marginTop: 20 }}>
              <Typography variant='h5' component='h3' gutterBottom>
                    Error
              </Typography>
              <Typography component='p'>
                    Something went wrong.
              </Typography>

            </Paper>
          )}

        </Grid>
      </Fragment>
    )
  }

  HouseholdInvite = ({ location }) => {
    const { props } = this
    const { cookies } = props
    const token = cookies.get('token')

    let params = new URLSearchParams(location.search)
    let invite = params.get('invite')

    return (
      <Fragment>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          {invite ? (
            <ConfirmInvite token={token} inviteId={invite} type={'household'} />
          ) : (
            <Paper style={{ padding: 20, marginTop: 20 }}>
              <Typography variant='h5' component='h3' gutterBottom>
                    Error
              </Typography>
              <Typography component='p'>
                    Something went wrong.
              </Typography>
            </Paper>
          )}

        </Grid>
      </Fragment>
    )
  }

  NoMatch = () => {
    return (
      <Fragment>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Paper style={{ padding: 20, marginTop: 20 }}>
            <Typography variant='h5' component='h3' gutterBottom>
                    Error
            </Typography>
            <Typography component='p'>
                    Something went wrong.
            </Typography>
          </Paper>
          <NavigationMenu />
        </Grid>
      </Fragment>

    )
  }

  Logout = () => {
    const { props } = this
    const { cookies } = props

    return (
      <Fragment>
        <LogoutPage
          cookies={cookies} />
      </Fragment>
    )
  }

    Login = () => {
      const { props } = this
      const { cookies } = props

      return (
        <Fragment>
          <LoginPage
            cookies={cookies} />
        </Fragment>
      )
    }

    Scheduler = () => {
      const { props } = this
      const { cookies } = props

      return (
        <Fragment>
          <SchedulerPage
            cookies={cookies} />
        </Fragment>
      )
    }

    render () {
      const { SafetyNetwork, ChangePin, Household, SafetyNetworkInvite, HouseholdInvite, NoMatch, PrivateRoute, Loading, Logout, Login, Scheduler } = this
      const { state } = this
      const { isAuthenticating } = state
      const userPromise = UserInfo.getUserObject()

      return (
        <OptimizelyProvider
          optimizely={optimizelyClientInstance}
          timeout={1000}
          user={userPromise}
        >
          <React.Fragment>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Grid container justify='center'>
                {
                  isAuthenticating ? (
                    <Loading />
                  ) : (
                    <Switch>
                      <PrivateRoute exact path='/safety_network' component={SafetyNetwork} />
                      <PrivateRoute path={'/safety_network/scheduler'} component={Scheduler} />
                      <PrivateRoute path='/pin' component={ChangePin} />
                      <PrivateRoute path='/household' component={Household} />
                      <PrivateRoute path='/invite/safety_network' component={SafetyNetworkInvite} />
                      <PrivateRoute path='/invite/household' component={HouseholdInvite} />
                      <Route path='/logout' component={Logout} />
                      <Route path='/login' component={Login} />
                      <Route component={NoMatch} />
                    </Switch>
                  )
                }

              </Grid>
            </MuiThemeProvider>
          </React.Fragment>
        </OptimizelyProvider>
      )
    }
}

export default withCookies(App)
