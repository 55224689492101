import React, { Component } from 'react'
import '../styles/Header.css'
import logo from '../assets/logo.png'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
})

class Header extends Component {
  signOut = () => {
    const { props } = this
    const { history } = props
    history.push('/logout')
  }

  render () {
    const { props } = this
    const { classes } = props

    return (
      <div className={classes.root}>
        <AppBar color='default' position='static' elevation={1}>
          <Toolbar>
            <img className='logo' src={logo} alt='noonlight' height='30' />
            <Typography variant='h6' className={classes.title} />
            <Button color='primary' onClick={this.signOut}>Sign out</Button>
          </Toolbar>
        </AppBar>
      </div>

    )
  }
}

export default withRouter(withStyles(styles)(Header))
