
class NLUtils {
  static isProdEnv () {
    return this.getEnvTypeFromURL() === 'production'
  }

  static getEnvTypeFromURL () {
    if (window.location.hostname === 'manage.noonlight.com') {
      return 'production'
    } else {
      return 'beta'
    }
  }

    static formattedPhone = (string) => {
      let phone = this.unformatted10DigitPhone(string)
      if (phone) {
        return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`
      } else {
        return ''
      }
    }

    static stripNonNumerics = (string) => {
      return string ? string.replace(/\D/g, '') : ''
    }

    static unformatted10DigitPhone = (phone) => {
      return this.stripNonNumerics(phone).slice(-10)
    }

    static isPhoneValid = (phone) => {
      return !(phone ? (phone.length !== 10 || phone.charAt(0) === '1') : true)
    }

    static extractFirstName = (fullName) => {
      return fullName ? fullName.split(' ')[0] : ''
    }

    static isNameValid = (name) => {
      return !(name ? name.length <= 1 : true)
    }

    static generateNonce = () => {
      return this.randomString(10)
    }

    static randomString = (length) => {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    }

    static oAuthfetchRequest = (baseURI, token) => async (method = 'get', path, body = null) => {
      const opts = {
        method: method,
        headers: {
          'authorization': `bearer ${token}`,
          'content-type': 'application/json'
        },
        body
      }
      try {
        const response = await fetch(`${baseURI}/${path}`, opts)
        if (response.status === 401) return Promise.reject(new Error('noauth'))
        else return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    static stripParam (querystring, param) {
      querystring = querystring.split('?').pop()
      const prefix = encodeURIComponent(param) + '='
      const pars = querystring.split(/[&;]/g)
      for (let i = pars.length; i-- > 0;) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1)
        }
      }
      return pars.length > 0 ? '?' + pars.join('&') : ''
    }
}

export default NLUtils
