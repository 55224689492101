/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import '../styles/Household.css'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'

import Input from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { string } from 'prop-types'
import SafetyNetworkMemberRow from '../components/rows/safetyNetworkMemberRow'
import NLDialog from '../components/nlDialog'
import ContinueDialog from '../components/continueDialog'
import ListSubheader from '@material-ui/core/ListSubheader'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import SafetyNetworkService from '../services/safetyNetworkService'
import Divider from '@material-ui/core/Divider'
import NLUtils from '../utils/utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PhoneInput } from '../components/phoneInput'
import PendingInviteRow from '../components/rows/pendingInviteRow'
import { sortBy, values, mergeWith, isArray, transform } from 'lodash'
import PendingRequestAlert from '../components/pendingRequestAlert'
import { Analytics, AnalyticsEvent } from '../utils/analytics'
import { OptimizelyFeature, withOptimizely } from '@optimizely/react-sdk'
import { FeatureFlags } from '../utils/featureFlags'
import { Refresh, AccessTime } from '@material-ui/icons'
import LoadingButton from '../components/loadingButton'
import { ProductCopy } from '../strings/strings'
import TrackerPermissionsSettingsDialog from '../components/modals/trackerPermissionsSettingsDialog'
import TrackerNotificationsSettingsDialog from '../components/modals/trackerNotificationsSettingsDialog'
import Fab from '@material-ui/core/Fab'
import AuthService from '../services/authService'

class SafetyNetwork extends Component {
    static propTypes = {
      token: string.isRequired
    }

    constructor (props) {
      super(props)
      const { token, optimizely } = props
      this.safetyNetworkService = new SafetyNetworkService(token)
      this.optimizely = optimizely
      const { cookies } = props
      this.authService = new AuthService(cookies)

      this.state = {
        selectPermissionsDialogVisible: false,
        permissionPickerName: '',
        selectNotificationsPickerDialogVisible: false,
        notificationsPickerName: '',
        is_refreshing_network: false,
        featureFlags: {
          addFriendCtaFlagEnabled: false,
          noAppRequiredFlagEnabled: false,
          reloadButtonFlagEnabled: false,
          hideAddFriendCopyFlagEnabled: false,
          trackerPermissionsPicker: false,
          trackerNotificationsPicker: false
        },
        safety_network: {
          status: 'initializing'
        },
        dialogs: {
          addFriendDialog: {
            visible: false,
            phone: '',
            first_name: '',
            loading: false,
            error: null,
            validationErrors: {
              has_name_error: false,
              has_phone_error: false
            }
          },
          cancelInvite: {
            visible: false,
            loading: false,
            model: {
              name: ''
            },
            error: null
          },
          removeUser: {
            visible: false,
            loading: false,
            model: {
              name: ''
            },
            error: null
          },
          requestLocation: {
            visible: false,
            loading: false,
            model: {
              name: '',
              phone: ''
            },
            error: null
          },
          sendLocation: {
            visible: false,
            loading: false,
            model: {
              name: '',
              phone: ''
            },
            error: null
          },
          continue: {
            visible: false,
            model: {
              linkToAddFriend: ''
            }
          }
        }
      }
    }

    _handleChange (event, modalId) {
      const { state } = this
      const { id, value } = event.target

      state.dialogs[modalId][id] = value
      if (id === 'first_name') {
        if (state.dialogs.addFriendDialog.validationErrors.has_name_error) {
          state.dialogs.addFriendDialog.validationErrors.has_name_error = false
        }
      }
      this.setState(state)
    }

    _phoneChanged (phone, modalId) {
      const { state } = this
      state.dialogs[modalId].phone = phone
      // state.dialogs[modalId].phone = NLUtils.unformatted10DigitPhone(phone)
      state.dialogs.addFriendDialog.validationErrors.has_phone_error = false

      this.setState(state)
    }

    _openModalDialog = (modalId, model) => {
      const { state } = this
      const { dialogs } = state

      dialogs[modalId].visible = true
      dialogs[modalId].model = model
      this.setState(state)
    }

    _openContinueDialog = (modalId, model) => {
      const { state } = this
      const { dialogs } = state

      dialogs[modalId].visible = true
      dialogs[modalId].model = model
      this.setState(state)
    }

    closeModalHandler = (modalId) => {
      const { state } = this
      const { dialogs } = state

      dialogs[modalId].visible = false
      dialogs[modalId].error = null

      this.setState(state)
    }

    transformedPeoples = (members) => {
      return transform(members, function (result, value) {
        result[value.phone] = value
      }, {})
    }

    mergedMembers = (members, owners) => {
      let x = this.transformedPeoples(members)
      let y = this.transformedPeoples(owners)

      let merged = mergeWith(x, y, this.mergeCustomizer)
      return merged
    }

    mergeCustomizer = (objValue, srcValue) => { // if it's an array item, concat instead of overwrite
      if (isArray(objValue)) {
        return objValue.concat(srcValue)
      }
    }

    activePermissions = (scopes) => {
      let permissions = []
      if (scopes && scopes.length) {
        scopes.forEach((item) => {
          if (new Date(item.expiration_time) >= new Date()) {
            permissions.push(item.scope)
          }
        })
      }
      return permissions
    }

    async manuallyRefreshSafetyNetwork () {
      this.setState({ is_refreshing_network: true })
      new AnalyticsEvent(Analytics.categories.SAFETY_NETWORK, 'manual_reload').track()
      await this.refreshSafetyNetwork()
      if (this.pendingRequestAlert) {
        await this.pendingRequestAlert.refreshPendingPermissions()
      }
      this.setState({ is_refreshing_network: false })
    }

    showPermissionsSelectionDialog = (name) => {
      const { state } = this
      const { featureFlags } = state
      const { trackerPermissionsPicker } = featureFlags

      if (trackerPermissionsPicker) {
        new AnalyticsEvent('share_permissions', 'launched').track()
        this.setState({
          selectPermissionsDialogVisible: true,
          permissionPickerName: name
        })
      }
    }
    hidePermissionsSelectionDialog = () => {
      this.setState({
        selectPermissionsDialogVisible: false
      })
    }

    showNotificationsSelectionDialog = (name) => {
      const { state } = this
      const { featureFlags } = state
      const { trackerNotificationsPicker } = featureFlags

      if (trackerNotificationsPicker) {
        new AnalyticsEvent('share_notifications', 'launched').track()
        this.setState({
          selectNotificationsPickerDialogVisible: true,
          notificationsPickerName: name
        })
      }
    }

    hideNotificationsSelectionDialog = () => {
      this.setState({
        selectNotificationsPickerDialogVisible: false
      })
    }

    async refreshSafetyNetwork () {
      try {
        const safetyNetwork = await this.safetyNetworkService.fetchSafetyNetworkMembers()
        const { _id: id, owner, invitations: invitees, members } = safetyNetwork
        const { name, phone, picture_url: imageURL } = owner
        const formattedPhone = NLUtils.formattedPhone(phone)
        const safetyNetworkOwners = await this.safetyNetworkService.fetchSafetyNetworkOwners()

        let membersTransformed = members.map(member => {
          const { name, phone, profile_picture_url: imageURL, battery_level, last_activity, scopes: grantedScopes } = member
          const formattedPhone = NLUtils.formattedPhone(phone)
          const permissions = ['removeMember', 'sendLocation'] // default permissions for 'members', merges w/ the dynamic permissions later
          const granted_permissions = this.activePermissions(grantedScopes)
          return { name, phone, formattedPhone, imageURL, battery_level, last_activity, permissions, granted_permissions }
        })

        let ownersTransformed = safetyNetworkOwners.map(owner => {
          const { name, phone, scopes } = owner
          const formattedPhone = NLUtils.formattedPhone(phone)
          let permissions = ['requestLocation']
          permissions = permissions.concat(this.activePermissions(scopes))

          return { name, phone, formattedPhone, permissions }
        })

        // merge the members & owners together so there aren't duplicate name rows
        let merged = this.mergedMembers(ownersTransformed, membersTransformed)
        let mergedMembers = values(merged)
        mergedMembers = sortBy(mergedMembers, ['name', 'phone'])

        this.setState({
          safety_network: {
            id,
            status: 'ready',
            owner: { name, phone, formattedPhone, imageURL },
            invitees: invitees.map(invitee => {
              const { name, phone } = invitee
              const formattedPhone = NLUtils.formattedPhone(phone)
              return { name, phone, formattedPhone }
            }),
            members: mergedMembers
          }
        })
      } catch (error) {
        if (error.message === 'noauth') {
          const { location } = this.props
          this.authService.login(location, { forceRedirect: true, stripParams: ['token'] })
        } else {
          this.setState({ safety_network: { status: 'error' } })
          console.error(error)
        }
      }
    }

    async componentWillMount () {
      await this.refreshSafetyNetwork()
      const { state, props } = this
      const { safety_network } = state
      const { location, token } = props
      const { invitees, members } = safety_network
      if (invitees.length === 0 && members.length === 0) {
        if (location && location.search) {
          const queryParams = new URLSearchParams(location.search)
          let device = queryParams.get('device')
          if (device) {
            let linkToAddFriend = `noonlight://view/safety-network/add-contact?token=${token}&networkId=${safety_network.id}`
            this._openContinueDialog('continue', { linkToAddFriend })
          } else {
            this._openModalDialog('addFriendDialog')
          }
        } else {
          this._openModalDialog('addFriendDialog')
        }
      }
      this.optimizely.client.onReady().then(() => {
        const addFriendCtaFlagEnabled = this.optimizely.isFeatureEnabled(FeatureFlags.addFriendCta)
        const noAppRequiredFlagEnabled = this.optimizely.isFeatureEnabled(FeatureFlags.noAppRequired)
        const reloadButtonFlagEnabled = this.optimizely.isFeatureEnabled(FeatureFlags.reloadButton)
        const hideAddFriendCopyFlagEnabled = this.optimizely.isFeatureEnabled(FeatureFlags.hideAddFriendCopy)
        const trackerPermissionsPicker = this.optimizely.isFeatureEnabled(FeatureFlags.trackerPermissionsPicker)
        const trackerNotificationsPicker = this.optimizely.isFeatureEnabled(FeatureFlags.trackerNotificationsPicker)

        this.setState({
          featureFlags: {
            addFriendCtaFlagEnabled,
            noAppRequiredFlagEnabled,
            reloadButtonFlagEnabled,
            hideAddFriendCopyFlagEnabled,
            trackerPermissionsPicker,
            trackerNotificationsPicker
          }
        })
      })
    }

    validateInviteMemberFormValues (name, phone) {
      const { state } = this
      const { dialogs } = state
      const { addFriendDialog } = dialogs
      const { validationErrors } = addFriendDialog

      const nameError = !NLUtils.isNameValid(name)
      const phoneError = !NLUtils.isPhoneValid(phone)

      validationErrors.has_name_error = nameError
      validationErrors.has_phone_error = phoneError

      this.setState(state)

      return !(nameError || phoneError) // returns true when there are no errors
    }

    async inviteBack (member) {
      const { state } = this

      let firstName = member.name.split(' ').shift()

      state.dialogs.addFriendDialog.first_name = firstName
      state.dialogs.addFriendDialog.phone = member.phone

      this._openModalDialog('addFriendDialog')
    }

    async stopSharingLocationWith (member) {
      const { safetyNetworkService } = this
      const { phone: memberPhone } = member

      try {
        const resp = await safetyNetworkService.stopSharingLocation(memberPhone)

        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        await this.refreshSafetyNetwork()
        Analytics.track('location_share', 'stop', member.phone)
      } catch (error) {
        console.log(error)
      }
    }

    async getLocationUrl (member) {
      const { safetyNetworkService, props } = this
      const { token } = props
      const { phone: memberPhone } = member

      try {
        const resp = await safetyNetworkService.getLocationUrl(memberPhone)
        let urlObj = await resp.json()

        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }

        let { url } = urlObj
        url = `${url}&token=${token}`
        return url
      } catch (error) {
        console.log(error)
      }
    }

    async sendInvite () {
      const { state } = this
      const { first_name, phone: formattedPhone } = state.dialogs.addFriendDialog
      const { safety_network } = state

      const phone = NLUtils.unformatted10DigitPhone(formattedPhone)

      if (!this.validateInviteMemberFormValues(first_name, phone)) {
        return
      }

      const modalId = 'addFriendDialog'
      try {
        console.log('send')
        this.setDialogToLoadingState(modalId)

        const resp = await this.safetyNetworkService.inviteMember(first_name, phone, safety_network.id)
        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        state.safety_network.invitees.push({
          name: first_name,
          phone: phone,
          formattedPhone: NLUtils.formattedPhone(phone)
        })
        await this.refreshSafetyNetwork()
        Analytics.events.sendSafetyNetworkInvite.track(phone)
        this.setDialogToFreshHiddenState(modalId)

        state.dialogs.addFriendDialog.first_name = ''
        state.dialogs.addFriendDialog.phone = ''

        this.setState(state)

        this.showPermissionsSelectionDialog(first_name)
        this.showNotificationsSelectionDialog(first_name)
      } catch (error) {
        const errorMessage = error.message
        const event = new AnalyticsEvent(Analytics.categories.NETWORK_ERROR, 'send_invite', errorMessage)
        event.track()
        this.setDialogToErrorState(modalId, errorMessage)
      }
    }

    setDialogToErrorState (modalId, error) {
      const { state } = this
      state.dialogs[modalId].visible = true
      state.dialogs[modalId].loading = false
      state.dialogs[modalId].error = error || 'Invalid request'
      this.setState(state)
    }

    setDialogToLoadingState (modalId) {
      const { state } = this
      state.dialogs[modalId].visible = true
      state.dialogs[modalId].loading = true
      state.dialogs[modalId].error = null
      this.setState(state)
    }

    setDialogToFreshHiddenState (modalId) {
      const { state } = this
      state.dialogs[modalId].visible = false
      state.dialogs[modalId].loading = false
      state.dialogs[modalId].error = null
      this.setState(state)
    }

    async requestLocation () {
      const { state } = this
      const { safety_network } = state

      try {
        this.setDialogToLoadingState('requestLocation')

        const memberPhone = state.dialogs.requestLocation.model.phone
        const resp = await this.safetyNetworkService.requestLocationPermission(memberPhone, safety_network.id)

        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        Analytics.track('request', 'sent', memberPhone)
        this.setDialogToFreshHiddenState('requestLocation')
      } catch (error) {
        let errorMessage = error.message
        this.setDialogToErrorState('requestLocation', errorMessage)
      }
    }

    async sendLocation () {
      const { state } = this
      const { safety_network } = state

      try {
        console.log('send location')

        this.setDialogToLoadingState('sendLocation')

        const memberPhone = state.dialogs.sendLocation.model.phone
        const resp = await this.safetyNetworkService.sendLocationPermission(memberPhone, safety_network.id)
        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        await this.refreshSafetyNetwork()
        Analytics.track('location_share', 'start', memberPhone)
        this.setDialogToFreshHiddenState('sendLocation')
      } catch (error) {
        let errorMessage = error.message
        this.setDialogToErrorState('sendLocation', errorMessage)
      }
    }

    async removeUser () {
      const { state } = this
      const { safety_network } = state

      try {
        console.log('remove user')

        this.setDialogToLoadingState('removeUser')

        const memberPhone = state.dialogs.removeUser.model.phone
        const resp = await this.safetyNetworkService.removeMember(memberPhone, safety_network.id)

        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        await this.refreshSafetyNetwork()
        Analytics.track('networks_member', 'remove', memberPhone)
        this.setDialogToFreshHiddenState('removeUser')
      } catch (error) {
        this.setDialogToErrorState('removeUser', 'Invalid request')
      }
    }

    async cancelInvite () {
      const { state } = this
      const { safety_network } = state

      try {
        console.log('cancel invite')
        this.setDialogToLoadingState('cancelInvite')

        const inviteePhone = state.dialogs.cancelInvite.model.phone
        const resp = await this.safetyNetworkService.cancelInvitation(inviteePhone, safety_network.id)
        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        await this.refreshSafetyNetwork()
        Analytics.track('networks_invite', 'revoked', inviteePhone)
        this.setDialogToFreshHiddenState('cancelInvite')
      } catch (error) {
        this.setDialogToErrorState('cancelInvite', 'Invalid request')
      }
    }

    render () {
      const { state, props } = this
      const { safety_network, dialogs, is_refreshing_network } = state
      const { status, invitees, members } = safety_network
      const { location, token } = props
      const memberLength = (invitees ? invitees.length : 0) + (members ? members.length : 0)

      const { featureFlags } = state
      const { addFriendCtaFlagEnabled, noAppRequiredFlagEnabled, reloadButtonFlagEnabled, hideAddFriendCopyFlagEnabled } = featureFlags

      let redirectContext = null
      if (location && location.state && location.state.type) {
        redirectContext = location.state.type
      }

      const showInviteConfirmation = redirectContext === 'invite_accepted'

      let shouldDeepLink = false
      let linkToAddFriend = null
      if (location && location.search) {
        const queryParams = new URLSearchParams(location.search)
        let device = queryParams.get('device')
        if (device) {
          shouldDeepLink = true
          linkToAddFriend = `noonlight://view/safety-network/add-contact?token=${token}&networkId=${safety_network.id}`
        }
      }

      return (
        <div style={{ width: '100%' }} >
          <PendingRequestAlert token={token} onRef={ref => (this.pendingRequestAlert = ref)} />
          <Paper square elevation={0}>
            <Grid container>
              <Grid item xs={12}>
                {showInviteConfirmation &&
                <div className='confirmMessage'>
                  <h2>Welcome to Noonlight</h2>
                  <p>
                                    You’ve successfully joined {location.state.owner}'s safety network. Now what?
                                    Connect other apps and devices, manage your account, or trigger an alarm—all from
                                    the <a href='http://get.noonlight.com/app'>Noonlight app</a>.
                  </p>
                </div>
                }

                {(memberLength > 0) ? (
                  <Fragment>

                    {status === 'ready' &&

                    <List subheader={
                      <ListSubheader>My Safety Network

                        {reloadButtonFlagEnabled &&
                        <LoadingButton loading={is_refreshing_network}
                          hidesWhenLoading
                          disableRelativeContainer
                          size={'small'}
                          onClick={() => this.manuallyRefreshSafetyNetwork()}
                          color={'default'}
                        >
                          <Refresh />

                        </LoadingButton>
                        }
                      </ListSubheader>
                    }
                    style={{ paddingBottom: 0 }}>
                      <Divider light />

                      {members.map((member, key) =>
                        <SafetyNetworkMemberRow
                          name={member.name}
                          phone={member.formattedPhone}
                          battery_level={member.battery_level}
                          last_activity={member.last_activity}
                          avatar_url={member.imageURL}
                          capabilities={member.permissions}
                          granted_permissions={member.granted_permissions}
                          key={`member-${key}`}
                          onRemove={(e) => this._openModalDialog('removeUser', member)}
                          onSendLocation={(e) => this._openModalDialog('sendLocation', member)}
                          onRequestLocation={(e) => this._openModalDialog('requestLocation', member)}
                          onSendInvite={(e) => this.inviteBack(member)}
                          onStopSharing={(e) => this.stopSharingLocationWith(member)}
                          getLocationUrl={(e) => this.getLocationUrl(member)}
                        />
                      )}
                      {invitees.map((invitee, key) =>

                        <PendingInviteRow key={`invite-${key}`} invitee={invitee}
                          onClick={() => this._openModalDialog('cancelInvite', invitee)} />
                      )}
                    </List>

                    }
                  </Fragment>
                ) : (
                  <Paper style={{ margin: 20, padding: 20 }}>

                    {status === 'initializing' ? (
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>

                        <Typography variant='h5' component='h3' gutterBottom align={'center'}
                          style={{ marginTop: 25 }}>
                                                Loading...
                        </Typography>

                      </div>
                    ) : (
                      <div>
                        <Typography variant='h5' component='h3' gutterBottom>
                                                My Safety Network
                        </Typography>
                        <Typography component='p'> Allow your trusted friends to find you, no matter
                                                what happens.
                        </Typography>
                      </div>

                    )

                    }

                  </Paper>
                )}

              </Grid>
            </Grid>

            <NLDialog
              analytics_tag={'add_friend'}
              open={dialogs.addFriendDialog.visible}
              error={dialogs.addFriendDialog.error}
              onClose={() => this.closeModalHandler('addFriendDialog')}
              primaryCta={addFriendCtaFlagEnabled ? 'Add' : 'Send Invite'}
              onPrimaryClick={() => this.sendInvite()}
              primaryClickShouldClose={false}
              secondaryCta={'Cancel'}
              secondaryType={'default'}
              onSecondaryClick={() => console.log('cancel')}
              title={'Add a friend'}
              loading={dialogs.addFriendDialog.loading}
              topBody={
                noAppRequiredFlagEnabled ? `Share with anyone – no app is required to view` : null
              }
              body={hideAddFriendCopyFlagEnabled ? null : ProductCopy.featureDescription}
            >
              <form noValidate autoComplete='off'>
                <Input
                  error={dialogs.addFriendDialog.validationErrors.has_name_error}
                  type='text'
                  id='first_name'
                  label='First name'
                  margin='normal'
                  variant='filled'
                  fullWidth
                  InputProps={{
                    disableUnderline: true
                  }}
                  value={this.state.dialogs.addFriendDialog.first_name}
                  onChange={(e) => this._handleChange(e, 'addFriendDialog')}
                  disabled={dialogs.addFriendDialog.loading}
                  ref={input => input && input.focus()}
                />
                <PhoneInput
                  error={dialogs.addFriendDialog.validationErrors.has_phone_error}
                  onChange={(phone) => this._phoneChanged(phone, 'addFriendDialog')}
                  id='phone'
                  type='tel'
                  label='Phone number'
                  margin='normal'
                  variant='filled'
                  fullWidth
                  value={this.state.dialogs.addFriendDialog.phone}
                  disabled={dialogs.addFriendDialog.loading}

                />

              </form>
            </NLDialog>

            <NLDialog
              analytics_tag={'send_location'}
              open={dialogs.sendLocation.visible}
              loading={dialogs.sendLocation.loading}
              error={dialogs.sendLocation.error}
              onClose={() => this.closeModalHandler('sendLocation')}
              primaryClickShouldClose={false}
              primaryCta={'Send Location'}
              onPrimaryClick={() => this.sendLocation()}
              secondaryCta={'Not Now'}
              secondaryType={'default'}
              onSecondaryClick={() => console.log('cancel')}
              title={'Share your live location'}
              body={`Are you sure you want to share your live location with ${dialogs.sendLocation.model.name}`}
            />

            <NLDialog
              analytics_tag={'request_location'}
              open={dialogs.requestLocation.visible}
              loading={dialogs.requestLocation.loading}
              error={dialogs.requestLocation.error}
              onClose={() => this.closeModalHandler('requestLocation')}
              primaryClickShouldClose={false}
              primaryCta={ProductCopy.requestLocation.primaryCta}
              onPrimaryClick={() => this.requestLocation()}
              secondaryCta={'Not Now'}
              secondaryType={'default'}
              onSecondaryClick={() => console.log('cancel')}
              title={ProductCopy.requestLocation.modalTitle(dialogs.requestLocation.model.name)}
              body={ProductCopy.requestLocation.body(dialogs.requestLocation.model.name)}
            />

            <NLDialog
              open={dialogs.cancelInvite.visible}
              loading={dialogs.cancelInvite.loading}
              error={dialogs.cancelInvite.error}
              onClose={() => this.closeModalHandler('cancelInvite')}
              primaryClickShouldClose={false}
              primaryCta={'Remove'}
              primaryType={'secondary'}
              onPrimaryClick={() => this.cancelInvite()}
              secondaryCta={'Keep'}
              secondaryType={'primary'}
              onSecondaryClick={() => console.log('cancel')}
              title={'Cancel Invitation'}
              body={`Are you sure you want to remove ${dialogs.cancelInvite.model.name} from your Safety Network?`}
            />

            <NLDialog
              open={dialogs.removeUser.visible}
              loading={dialogs.removeUser.loading}
              error={dialogs.removeUser.error}
              onClose={() => this.closeModalHandler('removeUser')}
              primaryClickShouldClose={false}
              primaryCta={'Remove'}
              primaryType={'secondary'}
              onPrimaryClick={() => this.removeUser()}
              secondaryCta={'Keep'}
              secondaryType={'primary'}
              onSecondaryClick={() => console.log('cancel')}
              title={'Remove Friend'}
              body={`Are you sure you want to remove ${dialogs.removeUser.model.name} from your Safety Network?`}
            />

            <ContinueDialog
              analytics_tag={'continue'}
              open={dialogs.continue.visible}
              linkToAddFriend={linkToAddFriend}
              onClose={() => this.closeModalHandler('continue')}
              title={"Now it's time to add friends to your Safety Network!"}
            />

            <TrackerPermissionsSettingsDialog
              open={state.selectPermissionsDialogVisible}
              name={state.permissionPickerName}
              onClose={() => this.hidePermissionsSelectionDialog()}
            />
            <TrackerNotificationsSettingsDialog
              open={state.selectNotificationsPickerDialogVisible}
              name={state.notificationsPickerName}
              onClose={() => this.hideNotificationsSelectionDialog()}
            />

          </Paper>

          <Grid item>
            {(shouldDeepLink) ? (
              <Button size='large' color='primary' href={linkToAddFriend}>
                          + Add a friend
              </Button>
            ) : (
              <Button size='large' color='primary' onClick={() => this._openModalDialog('addFriendDialog')}>
                          + Add a friend
              </Button>
            )}

            <OptimizelyFeature feature={FeatureFlags.hideBottomCopy}>
              {(isEnabled) => (
                !isEnabled && (
                  <Typography paragraph style={{ marginLeft: 25, marginRight: 25, whiteSpace: 'pre-line' }}>
                    {ProductCopy.featureDescription}
                  </Typography>
                )
              )}

            </OptimizelyFeature>

          </Grid>
          <OptimizelyFeature feature={FeatureFlags.checkinScheduler}>
            {(isEnabled) => (
              isEnabled && (
                <Fab
                  color='primary'
                  size={'large'}
                  href={'/safety_network/scheduler'}
                  style={{
                    position: 'absolute',
                    right: 30,
                    bottom: 30
                  }} >
                  <AccessTime />
                </Fab>
              ))}
          </OptimizelyFeature>
        </div>
      )
    }
}

export default withOptimizely(withRouter(SafetyNetwork))
