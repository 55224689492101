/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import '../styles/Household.css'
import defaultAvatar from '../assets/generic-avatar.png'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Input from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { ReactComponent as HomeIcon } from '../assets/home-icon.svg'
import DeleteOutline from '@material-ui/icons/DeleteOutlined'
import { string } from 'prop-types'
import NLUtils from '../utils/utils'
import InputAdornment from '@material-ui/core/InputAdornment'
import Person from '@material-ui/icons/Person'
import LocalPhone from '@material-ui/icons/LocalPhone'
import Snackbar from '@material-ui/core/Snackbar'
import { PhoneInput } from '../components/phoneInput'
import NLDialog from '../components/nlDialog'
import CloseIcon from '@material-ui/icons/Close'
import HouseholdApiService from '../services/householdApiService'
import { Typography, Paper, Box } from '@material-ui/core'
import AuthService from '../services/authService'

class Household extends Component {
  static propTypes = {
    token: string.isRequired
  }

  state = {
    household: {
      status: 'initializing'
    },
    newMember: {
      error: false,
      errorTxt: '',
      firstName: '',
      phone: '',
      validationErrors: {
        has_name_error: false,
        has_phone_error: false
      }
    },
    dialog: {
      visible: false,
      type: 'alert',
      icon: undefined,
      iconColor: undefined,
      text: '',
      action: undefined
    },
    snackbar: {
      visible: false,
      message: ''
    },
    dialogs: {
      cancelInvite: {
        visible: false,
        loading: false,
        error: null,
        model: {
          name: ''
        }
      },
      removeUser: {
        visible: false,
        loading: false,
        error: null,
        model: {
          name: '',
          phone: ''
        }
      }
    }
  }

  constructor (props) {
    super(props)
    const { cookies } = props
    this.authService = new AuthService(cookies)

    this._fetchHousehold = this._fetchHousehold.bind(this)
    this._inviteMember = this._inviteMember.bind(this)
    this._removeMember = this._removeMember.bind(this)
    this._handleChange = this._handleChange.bind(this)

    this.householdApiService = new HouseholdApiService(props.token)
  }

  setDialogToErrorState (modalId, error) {
    const { state } = this
    state.dialogs[modalId].visible = true
    state.dialogs[modalId].loading = false
    state.dialogs[modalId].error = error || 'Invalid request'
    this.setState(state)
  }

  setDialogToLoadingState (modalId) {
    const { state } = this
    state.dialogs[modalId].visible = true
    state.dialogs[modalId].loading = true
    state.dialogs[modalId].error = null
    this.setState(state)
  }

  setDialogToFreshHiddenState (modalId) {
    const { state } = this
    state.dialogs[modalId].visible = false
    state.dialogs[modalId].loading = false
    state.dialogs[modalId].error = null
    this.setState(state)
  }

  async _fetchHousehold () {
    const { householdApiService } = this

    const response = await householdApiService.fetchHousehold()
    const households = await response.json()
    const household = households.shift()
    return household
  }

  validateInviteMemberFormValues (name, phone) {
    const { state } = this
    const { newMember } = state
    const { validationErrors } = newMember

    const nameError = !NLUtils.isNameValid(name)
    const phoneError = !NLUtils.isPhoneValid(phone)

    validationErrors.has_name_error = nameError
    validationErrors.has_phone_error = phoneError

    this.setState(state)

    return !(nameError || phoneError) // returns true when there are no errors
  }

  isInviteFormValid () {
    const { state } = this
    const { newMember } = state
    const { firstName, phone } = newMember

    const nameError = !NLUtils.isNameValid(firstName)
    const phoneError = !NLUtils.isPhoneValid(NLUtils.unformatted10DigitPhone(phone))

    return !(nameError || phoneError) // returns true when there are no errors
  }

  async _inviteMember () {
    const { householdApiService, state } = this
    const { household, newMember } = state
    const { id: householdId } = household
    const { firstName: name, phone: formattedPhone } = newMember

    const phone = NLUtils.unformatted10DigitPhone(formattedPhone)

    if (!this.validateInviteMemberFormValues(name, phone)) {
      return
    }

    try {
      const res = await householdApiService.inviteMember(phone, name, householdId)
      if (!res.ok) {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error)
      }
      state.household.invitees.push({
        name,
        phone,
        formattedPhone: this._formatPhone(phone)
      })
      state.newMember = {
        error: false,
        errorTxt: '',
        firstName: '',
        phone: '',
        validationErrors: {
          has_name_error: false,
          has_phone_error: false
        }
      }
      this.setState(state)
      this.showSnackbar('Invite sent!')
    } catch (error) {
      state.newMember.error = true
      state.newMember.errorTxt = error.message
      this.setState(state)
      console.error(error)
    }
  }

  async _removeMember (phone) {
    const { householdApiService, state } = this
    const { household } = state
    const { id: householdId, members } = household
    const modalId = 'removeUser'

    try {
      this.setDialogToLoadingState(modalId)

      const res = await householdApiService.removeMember(phone, householdId)
      if (!res.ok) {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error)
      }

      this.setDialogToFreshHiddenState(modalId)

      state.household.members = members.filter(member => member.phone !== phone)
      state.dialogs.removeUser.model.name = ''
      state.dialogs.removeUser.model.phone = ''
      this.setState(state)
    } catch (error) {
      this.setDialogToErrorState(modalId, 'Invalid request')
      console.error(error)
    }
  }

  async _cancelInvitation (phone) {
    const { householdApiService, state } = this
    const { id: householdId, invitees } = state.household

    const modalId = 'cancelInvite'

    try {
      this.setDialogToLoadingState(modalId)

      const res = await householdApiService.cancelInvitation(phone, householdId)

      if (!res.ok) {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error)
      }
      state.household.invitees = invitees.filter(invitee => invitee.phone !== phone)
      state.dialogs.cancelInvite.model.name = ''
      state.dialogs.cancelInvite.model.phone = ''
      this.setState(state)

      this.setDialogToFreshHiddenState(modalId)
    } catch (error) {
      this.setDialogToErrorState(modalId, 'Invalid request')
      console.error(error)
    }
  }

  _handleChange (event) {
    const { state } = this
    const { id, value } = event.target
    state.newMember[id] = value

    if (id === 'firstName') {
      if (state.newMember.validationErrors.has_name_error) {
        state.newMember.validationErrors.has_name_error = false
      }
    }

    this.setState(state)
  }

  _phoneChanged (phone) {
    const { state } = this

    state.newMember.phone = phone
    state.newMember.validationErrors.has_phone_error = false

    this.setState(state)
  }

  closeSnackbar = () => {
    const { state } = this
    const { snackbar } = state
    snackbar.visible = false
    this.setState(state)
  }

  showSnackbar = (message) => {
    const { state } = this
    const { snackbar } = state
    snackbar.visible = true
    snackbar.message = message
    this.setState(state)
  }

  _formatPhone = (phone) => `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`

  _linkReload = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  openModalDialog = (modalId, model) => {
    const { state } = this
    const { dialogs } = state

    dialogs[modalId].visible = true
    dialogs[modalId].model = model
    this.setState(state)
  }

  closeModalHandler = (modalId) => {
    const { state } = this
    const { dialogs } = state

    dialogs[modalId].visible = false
    dialogs[modalId].error = null

    this.setState(state)
  }

  async componentWillMount () {
    try {
      const household = await this._fetchHousehold()
      const { _id: id, owner, invitations: invitees, members } = household
      const { name, phone, picture_url: imageURL } = owner
      const formattedPhone = this._formatPhone(phone)
      this.setState({
        household: {
          id,
          status: 'ready',
          owner: { name, phone, formattedPhone, imageURL },
          invitees: invitees.map(invitee => {
            const { name, phone } = invitee
            const formattedPhone = this._formatPhone(phone)
            return { name, phone, formattedPhone }
          }),
          members: members.map(member => {
            const { name, phone, profile_picture_url: imageURL } = member
            const formattedPhone = this._formatPhone(phone)
            return { name, phone, formattedPhone, imageURL }
          })
        }
      })
    } catch (error) {
      if (error.message === 'noauth') {
        const { location } = this.props
        this.authService.login(location, { forceRedirect: true, stripParams: ['token'] })
      } else {
        this.setState({ household: { status: 'error' } })
      }
      console.error(error)
    }
  }

  render () {
    const { state, props } = this
    const { classes } = props
    const { household, newMember, dialogs } = state
    const { error, errorTxt, firstName, phone } = newMember
    const { status, owner, invitees, members } = household
    return (
      <div className='household'>
        <Grid container>
          <Grid item xs={12}>
            { status === 'error' &&
              <div className='householdBody'>
                <header>
                  <h2>Network error</h2>
                  <p className='errorDesc'>
                    Please <a href='#reload' onClick={this._linkReload}>reload.</a>
                  </p>
                </header>
              </div>
            }
            { status === 'ready' &&

            <Paper className={classes.paper} >
              <Grid container justify={'center'}>
                <Grid item xs={10}>
                  <HomeIcon className='homeIcon' height='36' />

                  <Typography className={classes.header} align={'center'} variant='h6' gutterBottom>
                            Who else lives with you?
                  </Typography>

                  <Typography align={'center'} variant='body1' gutterBottom>
                            We’ll notify your household during an emergency,
                            allowing any member to cancel.
                  </Typography>

                </Grid>
                <Grid item xs={12}>
                  <List>
                    <Divider />
                    <ListItem alignItems='flex-start' divider>
                      <ListItemAvatar>
                        <Avatar alt={owner.name} src={owner.imageURL || defaultAvatar} />
                      </ListItemAvatar>
                      <ListItemText primary={owner.name} secondary={owner.formattedPhone} />
                      <ListItemSecondaryAction>
                        <Typography className={classes.successText} variant='caption' >Primary</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {invitees.map((invitee, key) =>
                      <ListItem key={key} alignItems='flex-start' divider>
                        <ListItemAvatar>
                          <Avatar alt={invitee.name} src={defaultAvatar} />
                        </ListItemAvatar>
                        <ListItemText primary={invitee.name} secondary={invitee.formattedPhone} />
                        <ListItemSecondaryAction onClick={() => this.openModalDialog('cancelInvite', {
                          phone: invitee.phone,
                          name: invitee.name
                        })}>
                          <Typography className={classes.warningText} variant='caption' >Pending</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                    {members.map((member, key) =>
                      <ListItem key={key} alignItems='flex-start' divider>
                        <ListItemAvatar>
                          <Avatar alt={member.name} src={member.imageURL || defaultAvatar} />
                        </ListItemAvatar>
                        <ListItemText primary={member.name} secondary={member.formattedPhone} />
                        <ListItemSecondaryAction onClick={() => this.openModalDialog('removeUser', {
                          name: member.name,
                          phone: member.phone
                        })}>
                          <IconButton aria-label='Delete' edge='end'>
                            <DeleteOutline fontSize='small' style={{ margin: -5 }} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Divider />

                <Grid item xs={10} >
                  <Box mt={3}>
                    <Typography align={'left'} variant='body2'>
                              Add member
                    </Typography>

                    <form noValidate autoComplete='off' style={{ marginTop: 0 }}>
                      {error && <p className='footerError'>{errorTxt}</p>}

                      <Input
                        error={newMember.validationErrors.has_name_error}
                        style={{ marginBottom: 15 }}
                        type='text'
                        id='firstName'
                        label='First name'
                        margin='normal'
                        variant='filled'
                        fullWidth
                        value={firstName}
                        onChange={this._handleChange}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Person />
                            </InputAdornment>
                          )
                        }}
                      />

                      <PhoneInput
                        error={newMember.validationErrors.has_phone_error}
                        onChange={(phone) => this._phoneChanged(phone)}
                        id='phone'
                        type='tel'
                        value={phone}
                        label='Phone number'
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <LocalPhone />
                            </InputAdornment>
                          )
                        }}
                      />

                      <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        disabled={!this.isInviteFormValid()}
                        fullWidth
                        className={classNames(classes.sendInviteBtn)}
                        onClick={() => this._inviteMember()}> Send Invite
                      </Button>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Paper>

            }
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.snackbar.visible}
          autoHideDuration={3000}
          onClose={() => this.closeSnackbar()}
          message={<span id='message-id'>{this.state.snackbar.message}</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <NLDialog
          open={dialogs.cancelInvite.visible}
          loading={dialogs.cancelInvite.loading}
          error={dialogs.cancelInvite.error}
          onClose={() => this.closeModalHandler('cancelInvite')}
          primaryClickShouldClose={false}
          primaryCta={'Remove'}
          primaryType={'secondary'}
          onPrimaryClick={() => this._cancelInvitation(dialogs.cancelInvite.model.phone)}
          secondaryCta={'Keep'}
          secondaryType={'primary'}
          onSecondaryClick={() => console.log('cancel')}
          title={'Cancel Invitation'}
          body={`Are you sure you want to remove ${dialogs.cancelInvite.model.name} from your household?`}
        />

        <NLDialog
          open={dialogs.removeUser.visible}
          loading={dialogs.removeUser.loading}
          error={dialogs.removeUser.error}
          onClose={() => this.closeModalHandler('removeUser')}
          primaryClickShouldClose={false}
          primaryCta={'Remove'}
          primaryType={'secondary'}
          onPrimaryClick={() => this._removeMember(dialogs.removeUser.model.phone)}
          secondaryCta={'Keep'}
          secondaryType={'primary'}
          onSecondaryClick={() => console.log('cancel')}
          title={'Remove Household Member'}
          body={`Are you sure you want to remove ${dialogs.removeUser.model.name} from your household`}
        />
      </div>

    )
  }
}

const styles = theme => ({
  sendInviteBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    padding: 6,
    marginTop: 14,
    border: '1px solid',
    borderRadius: 45,
    backgroundColor: '#166efb',
    borderColor: '#166efb',
    '&:disabled': {
      borderColor: 'transparent',
      backgroundColor: '#F5F5F5',
      color: '#9CA3AD'
    },
    '&:hover': {
      backgroundColor: '#0066cc',
      borderColor: '#0066cc'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0066cc',
      borderColor: '#0066cc'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  },
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 50,
    paddingBottom: 50
  },
  header: {
    fontWeight: 700
  },
  warningText: {
    color: '#EEC248'
  },
  successText: {
    color: '#57C58B'
  }
})

export default withRouter(withStyles(styles)(Household))
