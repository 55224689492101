import React, { Component, Fragment } from 'react'
import AuthService from '../services/authService'
import { CircularProgress } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

class LoginPage extends Component {
  constructor (props) {
    super(props)
    const { cookies } = props
    this.authService = new AuthService(cookies)
  }
  componentDidMount () {
    const { props } = this

    let returnLocation = {}
    if (props.location && props.location.state && props.location.state.from) {
      returnLocation = props.location.state.from
    }
    this.authService.login(returnLocation, { stripParams: ['token'] })
  }

  render () {
    return (
      <Fragment>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Paper style={{ padding: 20, marginTop: 20 }}>
            <CircularProgress />
          </Paper>
        </Grid>
      </Fragment>

    )
  }
}

export default withRouter(LoginPage)
