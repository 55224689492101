import React, { Component } from 'react'
import '../styles/ConfirmInvite.css'
import Grid from '@material-ui/core/Grid'
import runtimeEnv from '@mars/heroku-js-runtime-env'
import { string } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Analytics } from '../utils/analytics'

export default class ConfirmInvite extends Component {
  static propTypes = {
    token: string.isRequired,
    inviteId: string.isRequired,
    type: string.isRequired
  }

  state = {
    serverError: false,
    networkError: false,
    message: false,
    owner: ''
  }

  constructor () {
    super()
    this._acceptInvite = this._acceptInvite.bind(this)
  }

  async _acceptInvite () {
    const { state, props } = this
    const { token, inviteId, type } = props
    const env = runtimeEnv()
    const opts = {
      method: 'post',
      headers: { 'authorization': `bearer ${token}` }
    }

    const typePath = type === 'household' ? 'households' : 'networks'
    try {
      const res = await fetch(`${env.REACT_APP_ACCOUNT_SERVICES_URL}/profile/${typePath}/${inviteId}/member`, opts)
      if (!res.ok) {
        const errorResponse = await res.json()
        const error = new Error(errorResponse.error)
        console.error(error.message)

        if (error.message.indexOf('already exists') > 0 && type !== 'household') { // TODO: Households doesn't show anything if we redirect an invited user there (it will show them their household, which will be empty and confuse them). Putting a TODO here to remember this when it's worth solving.
          return this.setState({
            is_existing_member: true
          })
        } else {
          state.serverError = true
          return this.setState(state)
        }
      }
      const household = await res.json()
      Analytics.track(`${typePath}_invite`, 'accept', inviteId)

      state.message = true
      state.owner = household.owner.name
      this.setState(state)
    } catch (error) {
      console.error(error)
      state.networkError = true
      this.setState(state)
    }
  }

  componentWillMount () {
    this._acceptInvite()
  }

  _linkReload = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  SuccessRedirect = ({ component: Component, type, ...rest }) => {
    const { owner } = rest

    return (
      <Redirect
        to={{
          pathname: '/safety_network',
          state: {
            type: 'invite_accepted',
            owner: owner
          }
        }}
      />
    )
  }

  ExistingMemberRedirect = ({ component: Component, type }) => {
    const path = type === 'safety network' ? '/safety_network' : '/household'

    return (
      <Redirect
        to={{
          pathname: path
        }}
      />
    )
  }

  render () {
    const { state, props } = this
    const { message, networkError, serverError, owner, is_existing_member } = state
    const { type } = props
    const { SuccessRedirect, ExistingMemberRedirect } = this

    return (

      <div className='household'>
        { (message && type === 'safety network') && // redirects user to the safety network page - can be modified to pass in type to work for both safety network and household int he futre
          <SuccessRedirect owner={owner} />
        }

        { is_existing_member &&
          <ExistingMemberRedirect type={type} />
        }

        <Grid container>
          <Grid item xs={12}>
            <div className='confirmInviteBody'>
              { message &&
              <div className='confirmMessage'>
                <h2>Welcome to Noonlight</h2>
                <p>
                  You’ve successfully joined { owner }’s {type}. Now what?
                  Connect other apps and devices, manage your account, or trigger an alarm—all from the <a href='http://get.noonlight.com/app'>Noonlight app</a>.
                </p>
              </div>
              }
              { networkError &&
              <div className='errorMessage'>
                <h2>Network error</h2>
                <p className='errorDesc'>
                  Please <a href='#reload' onClick={this._linkReload}>reload.</a>
                </p>
              </div>
              }
              { serverError &&
              <div className='errorMessage'>
                <h2>Good news and bad news</h2>
                <p className='errorDesc'>
                  Good news is, you’ve successfully signed in to your Noonlight account!
                  Bad news is, we can’t find a {type} invitation for this phone number.
                  This means you may have been removed from the {type}, or signed in with
                  a different number.<br /><br />
                  Please <a href='http://help.noonlight.com/'>contact us</a> to learn more.
                </p>
              </div>
              }
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}
