import React, { Component, Fragment } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import NLDialog from '../components/nlDialog'
import TextField from '@material-ui/core/TextField'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { withRouter } from 'react-router-dom'
import { AnalyticsEvent } from '../utils/analytics'

class SchedulerPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hours: 1,
      message: '',
      dialogs: {
        schdulerBeta: {
          visible: false,
          loading: false
        }
      }
    }
  }

    addHour = () => {
      const { state } = this
      const hours = Math.min(state.hours + 1, 48)
      this.setState({
        hours: hours
      })
    }

    subtractHour = () => {
      const { state } = this
      const hours = Math.max(state.hours - 1, 1)
      this.setState({
        hours: hours
      })
    }

    _openModalDialog = (modalId, model) => {
      const { state } = this
      const { dialogs } = state

      dialogs[modalId].visible = true
      dialogs[modalId].model = model
      this.setState(state)
    }

    createSchedule = () => {
      this._openModalDialog('schdulerBeta')

      const truncMsg = `${this.state.hours}-${this.state.message.substr(0, 20)}`
      new AnalyticsEvent('checkin', 'scheduled', truncMsg).track()
    }

    closeModalHandler = (modalId) => {
      const { state } = this
      const { dialogs } = state

      dialogs[modalId].visible = false
      dialogs[modalId].error = null

      this.setState(state)
    }

    handleChange = name => event => {
      let state = this.state
      state[name] = event.target.value
      this.setState(state)
    }

    goBack = () => {
      const { props } = this
      const { history } = props

      history.goBack()
    }

    render () {
      const { state, props } = this
      const { hours } = state
      const { classes } = props

      const endTime = moment().add(hours, 'hours').calendar()

      return (
        <Fragment>
          <AppBar position='static'
            color={'default'}
          >
            <Toolbar>
              <IconButton edge='start' color='inherit' aria-label='menu' onClick={() => this.goBack()}>
                <ArrowBackIosIcon />
                <Typography>
                                Back
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>

          <Paper style={{ padding: 20, margin: 20, width: '100%' }}>
            <Grid container
              spacing={3} >
              <Grid item xs={12}>
                <Typography
                  variant={'h6'} >
                                Set a time to check in by
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex'
                  flexDirection={'row'}>
                  <Box flexGrow={1}>

                    <Typography>
                      { endTime }
                    </Typography>
                    <Typography
                      variant={'caption'} >
                                        In {hours} {hours > 1 ? 'hours' : 'hour' }
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      color={'default'}
                      variant={'contained'}
                      className={classes.circleButton}
                      onClick={(e) => this.subtractHour()}>
                                        -
                    </Button>

                    <Button
                      color={'default'}
                      variant={'contained'}
                      className={classes.circleButton}
                      onClick={(e) => this.addHour()}>
                                        +
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'h6'} >
                                Add a message
                </Typography>
                <Typography
                  variant={'caption'} >
                                It will be sent along with your last known location if you don't check in by {endTime}
                </Typography>
                <TextField
                  id='outlined-multiline-static'
                  label='Message'
                  multiline
                  value={this.state.message}
                  onChange={this.handleChange('message')}
                  fullWidth
                  rows='4'
                  margin='normal'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  fullWidth
                  onClick={() => this.createSchedule()}
                >
                                Create
                </Button>
              </Grid>
            </Grid>

            <NLDialog
              analytics_tag={'schdulerBeta'}
              open={this.state.dialogs.schdulerBeta.visible}
              onClose={() => this.closeModalHandler('schdulerBeta')}
              primaryCta={'Request Access'}
              onPrimaryClick={() => new AnalyticsEvent('beta-access', 'requested', 'schdulerBeta').track()}
              secondaryCta={'No Thanks'}
              secondaryType={'default'}
              primaryClickShouldClose
              secondaryClickShouldClose
              onSecondaryClick={() => new AnalyticsEvent('beta-access', 'declined', 'schdulerBeta').track()}
              title={'Request Beta Access?'}
              body={`This feature is currently in beta. Would you like to request beta access?`}
            />
          </Paper>
        </Fragment>

      )
    }
}

const styles = theme => ({
  circleButton: {
    borderRadius: 60,
    minWidth: 30,
    minHeight: 30,
    height: 30,
    width: 30,
    textTransform: 'none',
    fontSize: 18,
    color: '#344351',
    lineHeight: '12px',
    margin: 5,
    boxShadow: 'none'
  }
})

export default withRouter(withStyles(styles)(SchedulerPage))
