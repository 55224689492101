
let userInfo = {
  getDomainUserId: () => {
    return new Promise((resolve, reject) => {
      window.snowplow(function () {
        const co = this.co
        const domainUserId = co.getDomainUserId()
        resolve(domainUserId)
      })
    })
  },
  getUserObject: () => {
    return new Promise((resolve, reject) => {
      window.snowplow(function () {
        const co = this.co
        const domainUserId = co.getDomainUserId()
        resolve({
          id: domainUserId
        })
      })
    })
  }
}

export let UserInfo = userInfo
