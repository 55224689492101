import React, { Component } from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import { sortBy } from 'lodash'
import SafetyNetworkService from '../services/safetyNetworkService'
import LoadingButton from '../components/loadingButton'
import { ProductCopy } from '../strings/strings'

export default class PendingRequestAlert extends Component {
  constructor (props) {
    super(props)
    const { token } = props
    this.safetyNetworkService = new SafetyNetworkService(token)
    this.state = {
      pending_permissions: []
    }
  }

  async componentWillMount () {
    this.refreshPendingPermissions()
  }
  componentDidMount () {
    this.props.onRef(this)
  }
  componentWillUnmount () {
    this.props.onRef(undefined)
  }

  async refreshPendingPermissions () {
    try {
      const pendingPermissionsReq = await this.safetyNetworkService.fetchPendingPermissions()
      let pendingPermissions = await pendingPermissionsReq.json()

      this.setState({
        pending_permissions: pendingPermissions
      })
    } catch (e) {

    }
  }

    sortedPendingPermissions = () => {
      const { state } = this
      const { pending_permissions } = state
      return sortBy(pending_permissions, ['starts_at'])
    }

    firstPendingPermission = () => {
      const sortedPermissions = this.sortedPendingPermissions()
      return (sortedPermissions && sortedPermissions[0]) ? sortedPermissions[0] : null
    }

    setIsLoadingState = (isLoading) => {
      this.setState({
        is_loading: isLoading
      })
    }
    setIsDoneLoading = () => {
      this.setIsLoadingState(false)
    }
    setIsLoading = () => {
      this.setIsLoadingState(true)
    }

    async performActionOnPendingPermission (firstPendingPermission, shouldApprove) {
      try {
        this.setIsLoading()
        const resp = shouldApprove
          ? await this.safetyNetworkService.approvePendingPermission(firstPendingPermission._id) : await this.safetyNetworkService.rejectPendingPermission(firstPendingPermission._id)
        if (!resp.ok) {
          const errorResponse = await resp.json()
          throw new Error(errorResponse.error)
        }
        await this.refreshPendingPermissions() // refresh the pending invites list to make sure we've approved/rejected them all
        this.setIsDoneLoading()
      } catch (error) {
        this.setIsDoneLoading()
      }
    }

    render () {
      const { state } = this
      const { is_loading } = state
      const firstPendingPermission = this.firstPendingPermission()

      return (
        <React.Fragment>
          {firstPendingPermission &&
          <Box m={5}>
            <Card>

              <React.Fragment>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='h2'>
                      {ProductCopy.pendingRequest.title(firstPendingPermission.name)}
                    </Typography>
                    <Typography variant='body2' color='textSecondary' component='p'>
                      {ProductCopy.pendingRequest.body(firstPendingPermission.name)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <LoadingButton loading={is_loading}
                    onClick={() => this.performActionOnPendingPermission(firstPendingPermission, true)}
                    color={'default'}
                  >{ProductCopy.pendingRequest.secondaryCta}</LoadingButton>
                  <LoadingButton loading={is_loading}
                    onClick={() => this.performActionOnPendingPermission(firstPendingPermission, false)}
                    color={'primary'}>
                    {ProductCopy.pendingRequest.primaryCta}
                  </LoadingButton>
                </CardActions>
              </React.Fragment>

            </Card>
          </Box>
          }
        </React.Fragment>
      )
    }
}
