import React, { Component } from 'react'
import '../styles/ChangePin.css'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import InputMask from 'react-input-mask'
import runtimeEnv from '@mars/heroku-js-runtime-env'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { string } from 'prop-types'

class ChangePin extends Component {
  static propTypes = {
    token: string.isRequired
  }

  state = {
    error: false,
    errorTxt: '',
    pin: '',
    dialog: {
      visible: false,
      type: 'alert',
      icon: undefined,
      iconColor: undefined,
      text: '',
      action: undefined
    }
  }

  constructor () {
    super()
    this._submitPin = this._submitPin.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._closeDialog = this._closeDialog.bind(this)
  }

  async _submitPin () {
    const { state } = this
    const { token } = this.props
    const env = runtimeEnv()
    const pin = state.pin
    if (!/^\d{4}$/.test(pin)) {
      state.error = true
      state.errorTxt = 'PIN must be 4 digits long'
      return this.setState(state)
    }
    const opts = {
      method: 'put',
      headers: { 'content-Type': 'application/json', 'authorization': `bearer ${token}` },
      // headers: { 'content-Type': 'application/json', 'x-authenticated-userid': `5ca630c4ecb376e6217f1e57` },
      body: JSON.stringify({ pin })
    }
    try {
      const res = await fetch(`${env.REACT_APP_ACCOUNT_SERVICES_URL}/profile`, opts)
      if (!res.ok) {
        const errorResponse = await res.text()
        throw new Error(errorResponse.error)
      }
      const { state } = this
      const { dialog } = state
      state.error = ''
      state.dialog = Object.assign({}, dialog, { type: 'alert', text: 'PIN saved!', icon: CheckIcon, iconColor: 'primary', visible: true })
      setTimeout(() => {
        this._closeDialog(false)
      }, 2000)
      return this.setState(state)
    } catch (error) {
      state.error = true
      state.errorTxt = error.message
      console.error(error)
      return this.setState(state)
    }
  }

  _closeDialog = (action) => {
    const { state } = this
    const { dialog } = state
    if (action) {
      dialog.action()
    }
    state.pin = ''
    state.dialog = {
      visible: false,
      type: 'alert',
      icon: undefined,
      iconColor: undefined,
      text: '',
      action: undefined
    }
    this.setState(state)
  }

  _handleChange (event) {
    const { state } = this
    const { value } = event.target
    state.pin = value
    this.setState(state)
  }

  componentWillMount () {
  }

  _linkReload = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  render () {
    const { state, props } = this
    const { classes } = props
    const { pin, error, errorTxt, dialog } = state
    return (
      <div className='changePin'>
        <Grid container>
          <Grid item xs={12}>
            <div className='changePinBody'>
              <div className='changePinForm'>
                <p className='intro'>Update your Noonlight PIN</p>
                <p className='desc'>
                  Enter your new Noonlight PIN below. The PIN you enter below will be your PIN for cancelling any Noonlight alarms
                </p>
                <form noValidate autoComplete='off'>
                  <InputMask mask='9999' maskChar='' onChange={this._handleChange} value={pin}>
                    {
                      (inputProps) => <Input
                        type='tel'
                        id='firstName'
                        label='PIN'
                        margin='normal'
                        variant='outlined'
                        onChange={this._handleChange}
                      />
                    }
                  </InputMask>
                  {error && <p className='footerError'>{errorTxt}</p>}
                </form>
                <br />
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classNames(classes.savePinButton)}
                  onClick={() => this._submitPin()}> Save
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Dialog className='dialog' open={dialog.visible} onClose={() => this._closeDialog(false)} maxWidth='xs' fullWidth>
          <DialogContent>
            { dialog.icon && <dialog.icon fontSize='large' color={dialog.iconColor} />}
            <DialogContentText className='dialogText' variant='body1' color='textPrimary'>{dialog.text}</DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const styles = theme => ({
  savePinButton: {
    width: '70%',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    padding: 10,
    border: '1px solid',
    borderRadius: 45,
    backgroundColor: '#166efb',
    borderColor: '#166efb',
    '&:hover': {
      backgroundColor: '#0066cc',
      borderColor: '#0066cc'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0066cc',
      borderColor: '#0066cc'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})

export default withStyles(styles)(ChangePin)
