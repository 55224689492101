import React, { Component, Fragment } from 'react'
import AuthService from '../services/authService'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import NavigationMenu from '../components/navigationMenu'
class LogoutPage extends Component {
  constructor (props) {
    super(props)
    const { cookies } = props
    this.authService = new AuthService(cookies)
  }
  componentDidMount () {
    this.authService.logout()
  }

  render () {
    return (
      <Fragment>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Paper style={{ padding: 20, marginTop: 20 }}>
            <Typography variant='h5' component='h3' gutterBottom>
                            Logged Out
            </Typography>
            <Typography component='p'>
                            You successfully logged out.
            </Typography>
          </Paper>
          <NavigationMenu />
        </Grid>
      </Fragment>

    )
  }
}

export default LogoutPage
