import React, { Component, Fragment } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import defaultAvatar from '../../assets/generic-avatar.png'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { default as PropTypes } from 'prop-types'

export default class PendingInviteRow extends Component {
  render () {
    return <Fragment>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={this.props.invitee.name} src={defaultAvatar} />
        </ListItemAvatar>
        <ListItemText primary={this.props.invitee.name} secondary={this.props.invitee.formattedPhone}
          style={{ paddingTop: 5, paddingBottom: 5 }} />

        <ListItemSecondaryAction onClick={this.props.onClick}>
          <Typography align={'right'} display={'inline'} component={'span'} noWrap
            style={{ fontSize: '0.8em', color: '#EEC248' }}>
                        Pending
          </Typography>

        </ListItemSecondaryAction>

      </ListItem>
      <Divider light />
    </Fragment>
  }
}

PendingInviteRow.propTypes = {
  invitee: PropTypes.any,
  onClick: PropTypes.func
}
