import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import NLUtils from '../../utils/utils'
import { NotificationsActiveOutlined } from '@material-ui/icons'
import { withStyles, Typography } from '@material-ui/core'
import { AnalyticsEvent } from '../../utils/analytics'
import Checkbox from '@material-ui/core/Checkbox'

const styles = {
  subtext: {
    display: 'block',
    paddingRight: 10
  },
  headerIcon: {
    fontSize: '4rem'
  }
}

class TrackerNotificationsSettingsDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      arriveHome: false,
      leaveHome: false
    }
  }

    onToggle = (toggleName) => {
      const { state } = this
      const currentValue = !!state[toggleName]
      const newValue = !currentValue

      let action = 'toggled-off'
      if (newValue) {
        action = 'toggled-on'
      }
      new AnalyticsEvent('share_notifications', action, toggleName).track()

      state[toggleName] = newValue
      this.setState(state)
    }

    handleClose = () => {
      this.props.onClose && this.props.onClose()
    }

    clickedNotNow = () => {
      const activeToggles = this.getActiveTogglesString()
      new AnalyticsEvent('share_notifications', 'closed', activeToggles).track()
      this.handleClose()
    }

    getActiveTogglesString = () => {
      const { state } = this

      let activeToggles = []
      activeToggles.push(state.arriveHome ? 'arriveHome' : null)
      activeToggles.push(state.leaveHome ? 'leaveHome' : null)
      activeToggles = activeToggles.filter(n => n)
      return activeToggles
    }

    clickedDone = () => {
      const activeToggles = this.getActiveTogglesString()
      new AnalyticsEvent('share_notifications', 'saved', activeToggles).track()
      this.handleClose()
    }

    render () {
      const { props, state } = this
      const { arriveHome, leaveHome, other } = state
      const { open, name, classes } = props

      const firstName = NLUtils.extractFirstName(name)

      return (
        <Dialog
          fullScreen
          open={open}
        >
          <DialogTitle id='alert-dialog-title'>
            <Box textAlign='center' p={3}>
              <NotificationsActiveOutlined className={classes.headerIcon} />
              <Typography variant={'h5'}>
                {`Would you like to set up automatic notifications to ${firstName}?`}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem alignItems='flex-start' disableGutters>
                <ListItemIcon >
                  <NotificationsActiveOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={'Every Time I Arrive Home'}
                  secondary={
                    <Typography
                      variant='caption'
                      className={classes.subtext}
                      color='textPrimary'
                    >
                      {`We'll notify ${firstName} every time you arrive home.`}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    color='primary'
                    checked={arriveHome}
                    onChange={(e) => this.onToggle('arriveHome')}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem alignItems='flex-start' disableGutters>
                <ListItemIcon>
                  <NotificationsActiveOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={'Every Time I Leave Home'}
                  secondary={
                    <Typography
                      variant='caption'
                      className={classes.subtext}
                      color='textPrimary'
                    >
                      {`We'll notify ${firstName} every time you leave home.`}
                    </Typography>
                  } />
                <ListItemSecondaryAction>
                  <Checkbox
                    color='primary'
                    checked={leaveHome}
                    onChange={(e) => this.onToggle('leaveHome')}
                  />
                  {/* <IOSSwitch */}
                  {/*    edge="end" */}
                  {/*    onChange={(e) => this.onToggle('checkInRequests')} */}
                  {/* /> */}
                </ListItemSecondaryAction>
              </ListItem>

            </List>
          </DialogContent>
          <DialogActions>

            <Grid container spacing={2} alignItems={'stretch'} direction={'column'} justify={'center'}>
              <Grid item>
                <Box mx={2}>
                  <Button variant={'contained'} color='primary' fullWidth onClick={this.clickedDone}>
                                        Done
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mb={1} mx={2}>
                  <Button variant={'text'} color='default' fullWidth onClick={this.clickedNotNow}>
                                        Not Now
                  </Button>
                </Box>
              </Grid>
            </Grid>

          </DialogActions>
        </Dialog>
      )
    }
}

export default withStyles(styles)(TrackerNotificationsSettingsDialog)
