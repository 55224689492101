import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class NavigationMenu extends Component {
  render () {
    const { props } = this
    const { history } = props

    return (
      <Paper style={{ marginTop: 20 }}>
        <MenuList>
          <MenuItem onClick={() => history.replace('/safety_network')}>Safety Network</MenuItem>
          <MenuItem onClick={() => history.replace('/household')}>Household</MenuItem>
          <MenuItem onClick={() => history.replace('/change_pin')}>Change PIN</MenuItem>
        </MenuList>
      </Paper>

    )
  }
}

export default withRouter(NavigationMenu)
