import React from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props

  return (

    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask={false}
      guide={false}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export const PhoneInput = (props) => {
  const handleChange = event => {
    props.onChange(event.target.value)
  }

  return (
    <TextField
      onChange={handleChange}
      disabled={props.disabled}
      error={props.error}
      value={props.value}
      id='phone'
      label='Phone number'
      type='tel'
      variant='filled'
      name='phone'
      fullWidth
      InputProps={{
        disableUnderline: true,
        inputComponent: TextMaskCustom,
        startAdornment: props.InputProps ? props.InputProps.startAdornment : null,
        endAdornment: props.InputProps ? props.InputProps.endAdornment : null
      }}
    />

  )
}
