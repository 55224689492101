/* eslint-disable camelcase */
import runtimeEnv from '@mars/heroku-js-runtime-env'
import NLUtils from '../utils/utils'

class AuthService {
  constructor (cookies) {
    const env = runtimeEnv()
    this.endpoint = env.REACT_APP_AUTH_SERVICES_URL
    this.cookies = cookies
  }

  async oAuthfetchRequest (method = 'get', path, body = null) {
    const { token } = this
    const opts = {
      method: method,
      headers: {
        'authorization': `bearer ${token}`,
        'content-type': 'application/json'
      },
      body
    }
    return fetch(`${this.endpoint}/${path}`, opts)
  }

  async exchangeRefreshToken (refresh_token) {
    const body = JSON.stringify({
      refresh_token
    })

    return this.oAuthfetchRequest('post', 'auth/refresh/account-ui', body)
  }

  async reauthenticateAndUpdateCredentials () {
    const { refresh_token } = this.getOAuthCredentials()
    if (refresh_token) {
      const resp = await this.exchangeRefreshToken(refresh_token)
      const respJson = await resp.json()
      const { token } = respJson
      if (token) {
        this.setOAuthToken(token)
      }
    }
  }

    digestOAuthRedirectParams = (queryParams) => {
      const newToken = queryParams.get('token')
      const refreshToken = queryParams.get('refresh')

      if (newToken) {
        this.setOAuthToken(newToken)
      }
      if (refreshToken) {
        this.setOAuthRefreshToken(refreshToken)
      }
    }

    clearTokens = () => {
      const { cookies } = this
      console.log('clear tokens')

      cookies.remove('token')
      cookies.remove('refresh_token')
      cookies.remove('user_id')
    }

    logout = () => {
      if (this.hasToken() || this.hasRefreshToken() || this.hasUserId()) {
        this.clearTokens()
      }
    }

    login = (returnLocation, { forceRedirect, stripParams } = {
      forceRedirect: false,
      stripParams: []
    }) => {
      const env = runtimeEnv()
      const { token } = this.getOAuthCredentials()
      const location = returnLocation
      const cookies = this.cookies

      this.clearTokens()
      let { pathname: path, search } = location
      stripParams.forEach(param => {
        search = NLUtils.stripParam(search, param)
      })
      const stateKey = NLUtils.generateNonce()
      const stateVal = {
        path,
        search
      }

      const shouldRedirect = forceRedirect || !token
      let redirectPath = env.REACT_APP_AUTH_URL

      if (shouldRedirect) { // prepare the state
        cookies.set(stateKey, stateVal, {
          path: '/',
          maxAge: 60 * 60 * 1, // 1 hour
          secure: process.env.NODE_ENV === 'production'
        })
        redirectPath += `&state=${stateKey}`
      }
      if (location && location.search) {
        const queryParams = new URLSearchParams(location.search)
        let phoneHint = queryParams.get('phone_hint')
        if (phoneHint) {
          redirectPath += `&phone_hint=${phoneHint}`
        }
      }
      window.location.assign(redirectPath)
    }

    shouldRefreshToken = () => {
      return this.hasRefreshToken() && !this.hasToken()
    }

    hasUserId = () => {
      return !!this.getUserId()
    }

    hasToken = () => {
      return !!this.getOAuthCredentials().token
    }

    hasRefreshToken = () => {
      return !!this.getOAuthCredentials().refresh_token
    }

    getOAuthCredentials = () => {
      const { cookies } = this
      const token = cookies.get('token')
      const refresh_token = cookies.get('refresh_token')

      return { token, refresh_token }
    }

    async getUserInfo () {
      const env = runtimeEnv()
      const { token } = this.getOAuthCredentials()
      const opts = {
        method: 'get',
        headers: {
          'authorization': `bearer ${token}`,
          'content-type': 'application/json'
        }
      }
      return fetch(`${env.REACT_APP_ACCOUNT_SERVICES_URL}/profile/contact-info`, opts)
    }

    getUserId = () => {
      const { cookies } = this
      const user_id = cookies.get('user_id')

      return user_id
    }

    setUserId = (user_id) => {
      const { cookies } = this

      cookies.set('user_id', user_id, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365, // 365 days
        secure: process.env.NODE_ENV === 'production'
      })
    }

    setOAuthToken = (token) => {
      const { cookies } = this

      cookies.set('token', token, {
        path: '/',
        maxAge: 3600 * 9,
        secure: process.env.NODE_ENV === 'production'
      })
    }

    setOAuthRefreshToken = (refresh_token) => {
      const { cookies } = this

      cookies.set('refresh_token', refresh_token, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365, // 365 days
        secure: process.env.NODE_ENV === 'production'
      })
    }
}

export default AuthService
