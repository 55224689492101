
let featureFlags = {
  noAppRequired: 'no-app-required',
  addFriendCta: 'add-friend-cta',
  reloadButton: 'reload-button',
  hideAddFriendCopy: 'hide-add-friend-copy',
  trackerPermissionsPicker: 'tracker-permissions-picker',
  trackerNotificationsPicker: 'tracker-notifications-picker',
  hideBottomCopy: 'hide-bottom-copy',
  checkinScheduler: 'checkin-scheduler'
}

export let FeatureFlags = featureFlags
