import React, { Component } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { Analytics } from '../utils/analytics'

export default class ContinueDialog extends Component {
  handleClose = () => {
    this.props.onClose && this.props.onClose()
  }

  componentDidUpdate (prevProps) {
    if ((prevProps.open !== this.props.open) && this.props.open === true) {
      const tag = this.props.analytics_tag
      if (tag) {
        Analytics.track(`dialog`, 'open', tag)
      }
    }
  }

  render () {
    const { props } = this
    const { open, title, scroll, onEntered, linkToAddFriend } = props

    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' scroll={scroll || 'body'} onEntered={onEntered}>
        <DialogTitle id='alert-dialog-title'><h5>{title}</h5></DialogTitle>
        <DialogActions>

          <Button
            color={'primary'}
            variant={'contained'}
            fullWidth
            href={linkToAddFriend}
          >
            Continue
          </Button>

        </DialogActions>
      </Dialog>
    )
  }
}
