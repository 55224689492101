import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class LoadingButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      did_click: false
    }
  }

    handleClick = () => {
      this.props.onClick && this.props.onClick()
      this.setState({
        did_click: true
      })
    }

    componentDidUpdate (prevProps) {
      if ((prevProps.loading !== this.props.loading) && this.props.loading === false) {
        this.setState({
          did_click: false
        })
      }
    }

    render () {
      const { props, state } = this
      const { loading } = props
      const { hidesWhenLoading } = props
      const shouldShowLoadingAnimation = loading && state.did_click

      const shouldHideChildren = hidesWhenLoading && loading
      const { color, size, disableRelativeContainer } = props

      const buttonSize = size || 'medium'

      return <Button size={buttonSize} color={color} disabled={loading}
        onClick={this.handleClick}>
        {shouldShowLoadingAnimation &&

        <div style={{
          position: disableRelativeContainer ? 'inherit' : 'relative', // fix for weird issue in header of list
          top: '50%',
          left: '50%'
        }}>
          <Fade
            in={loading}
            style={{
              transitionDelay: false ? '800ms' : '0ms'
            }}
            unmountOnExit

          >
            <CircularProgress style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -12,
              marginLeft: -12
            }}
            size={24} />
          </Fade>
        </div>
        }
        {!shouldHideChildren &&
                props.children
        }
      </Button>
    }
}
