import React, { Component } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'
import { Analytics } from '../utils/analytics'

export default class NLDialog extends Component {
    static defaultProps = {
      primaryClickShouldClose: true,
      secondaryClickShouldClose: true
    }

    handleClose = () => {
      this.props.onClose && this.props.onClose()
    }

    handleClick = (button_id) => {
      if (button_id === 'SECONDARY') {
        this.props.onSecondaryClick && this.props.onSecondaryClick()
        if (this.props.secondaryClickShouldClose) {
          this.handleClose()
        }
      } else if (button_id === 'PRIMARY') {
        this.props.onPrimaryClick && this.props.onPrimaryClick()
        if (this.props.primaryClickShouldClose) {
          this.handleClose()
        }
      }
    }

    componentDidUpdate (prevProps) {
      if ((prevProps.open !== this.props.open) && this.props.open === true) {
        const tag = this.props.analytics_tag
        if (tag) {
          Analytics.track(`dialog`, 'open', tag)
        }
      }
    }

    render () {
      const { props } = this
      const { open, primaryCta, secondaryCta,
        title, body, secondaryType, primaryType,
        loading, error, scroll, onEntered, topBody } = props

      return (
        <Dialog open={open} onClose={this.handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' scroll={scroll || 'body'} onEntered={onEntered}>
          <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
          <DialogContent>
            { topBody &&
            <DialogContentText>
              {topBody}
            </DialogContentText>
            }

            <DialogContentText id='alert-dialog-description'>
              {body}
            </DialogContentText>

            {error &&
            <Typography color={'error'} >
              {error}
            </Typography>
            }

            {this.props.children}
          </DialogContent>
          <DialogActions>

            <Button disabled={loading} onClick={(e) => this.handleClick('SECONDARY')} color={secondaryType || 'secondary'}>
              {secondaryCta}
            </Button>
            <Button disabled={loading} onClick={(e) => this.handleClick('PRIMARY')} color={primaryType || 'primary'}>

              { loading &&
              <div style={{
                position: 'relative',
                top: '50%',
                left: '50%'
              }}>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: false ? '800ms' : '0ms'
                  }}
                  unmountOnExit

                >
                  <CircularProgress style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12
                  }}
                  size={24} />
                </Fade>
              </div>
              }
              <span>{primaryCta}</span>
            </Button>

          </DialogActions>
        </Dialog>
      )
    }
}
