
import NLUtils from '../utils/utils'

let productCopy = {
  featureDescription: `If your network can’t reach you and is worried you might be in trouble, they can send a check-in request. If you don’t respond, we’ll help them trace your steps to see if you’re in danger.\n\nThat way even if there’s a situation where you can’t use the button yourself (e.g. kidnapping), someone will know that something bad has happened and can get you help.`,
  requestLocation: {
    body: (memberName) => {
      return `If they are in danger or don't respond to the check-in within 5 minutes, we will share their live location with you. If they are OK, we'll let you know and we'll keep their location private.`
    },
    primaryCta: `Request a Check-In`,
    modalTitle: (memberName) => {
      return `Request a Check-In from ${NLUtils.extractFirstName(memberName)}?`
    }
  },
  pendingRequest: {
    title: (memberName) => {
      return `${NLUtils.extractFirstName(memberName)} wants to know if you are OK`
    },
    body: (memberName) => {
      return `${NLUtils.extractFirstName(memberName)} has requested a check-in from you. If you're in danger or don't respond within 5 minutes, we'll share your live location with ${NLUtils.extractFirstName(memberName)}. If you let us know you're safe, we'll keep your location private.`
    },
    primaryCta: `I'm Safe`,
    secondaryCta: `I Feel Unsafe`
  },
  shareLocation: {
    primaryCta: `Share live location for 24 hours`
  }
}

export let ProductCopy = productCopy
