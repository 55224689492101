import React, { Component } from 'react'
import '../../styles/Header.css'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import defaultAvatar from '../../assets/generic-avatar.png'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import RoomIcon from '@material-ui/icons/RoomOutlined'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Divider from '@material-ui/core/Divider'
import SendIcon from '@material-ui/icons/Send'
import PersonAdd from '@material-ui/icons/PersonAddOutlined'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import NearMeIcon from '@material-ui/icons/NearMe'
import MapIcon from '@material-ui/icons/MapOutlined'
import { isEqual } from 'lodash'
import { ProductCopy } from '../../strings/strings'
import { withOptimizely } from '@optimizely/react-sdk'
import { KeyboardArrowRight } from '@material-ui/icons'

class SafetyNetworkMemberRow extends Component {
  static propTypes = {
    // name: func.isRequired,
    // battery_level: func.isRequired,
    // last_activity: func.isRequired,
    // // avatar_url: func.isRequired,
  }

  constructor (props) {
    super(props)

    const { capabilities, granted_permissions } = props
    this.state = {
      open: false,
      permissions: this.getPermissionsObj(capabilities),
      grantedPermissions: this.getGrantedPermissionsObj(granted_permissions)
    }
  }

  getGrantedPermissionsObj = function (granted_permissions) {
    return {
      canReadLocation: this.containsPermission(granted_permissions, 'read:location')
    }
  }

  getPermissionsObj = function (capabilities) {
    return {
      requestLocation: this.containsPermission(capabilities, 'requestLocation'),
      sendLocation: this.containsPermission(capabilities, 'sendLocation'),
      removeMember: this.containsPermission(capabilities, 'removeMember'),
      viewLocation: this.containsPermission(capabilities, 'read:location')
    }
  }

  toggleItem = function () {
    const { state } = this
    const toggled = !state.open

    this.setState({
      open: toggled
    })
  }

  containsPermission = (permissions, permission) => {
    return permissions && permissions.indexOf(permission) >= 0
  }

  componentDidUpdate (prevProps, prevState) {
    const permissions = this.getPermissionsObj(this.props.capabilities)
    const grantedPermissions = this.getGrantedPermissionsObj(this.props.granted_permissions)

    if (!isEqual(permissions, this.state.permissions) || !isEqual(grantedPermissions, this.state.grantedPermissions)) {
      this.setState({
        permissions: permissions,
        grantedPermissions: grantedPermissions
      })
      if (permissions.viewLocation && !this.state.tripUrl) {
        this.fetchLocationUrl()
      }
    }
  }

  async fetchLocationUrl () {
    const { props } = this
    let url = await props.getLocationUrl()
    if (url) {
      this.setState({
        tripUrl: url
      })
    }
  }

  async componentWillMount () {
    const { state } = this
    if (state.permissions.viewLocation) {
      await this.fetchLocationUrl()
    }
  }

  render () {
    const { state, props } = this
    const { name, avatar_url, phone } = props
    const { open, tripUrl } = state
    const { permissions, grantedPermissions } = state

    return (
      <div>

        <ListItem onClick={() => this.toggleItem()}>
          <ListItemAvatar>
            <Avatar alt={name} src={avatar_url || defaultAvatar} />
          </ListItemAvatar>
          <ListItemText
            primary={name}
            secondary={
              grantedPermissions.canReadLocation ? 'Can see your location' : phone
            }
          />

          <ListItemSecondaryAction onClick={() => this.toggleItem()}>
            <IconButton edge='end' aria-label='Delete'>
              {
                grantedPermissions.canReadLocation &&
                <NearMeIcon />
              }
            </IconButton>
            <IconButton edge='end' aria-label='Delete'>
              { open ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowRight />
              )}

            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            { (permissions.requestLocation && !permissions.viewLocation) &&
            <ListItem button key={'1'} onClick={props.onRequestLocation}>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText primary={ProductCopy.requestLocation.primaryCta} />
            </ListItem>
            }

            { (permissions.viewLocation && tripUrl) &&
            <ListItem button key={'view-location'} component='a' href={tripUrl} target={'_blank'}>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary='View Location' />
            </ListItem>
            }
            { (permissions.sendLocation && !grantedPermissions.canReadLocation) &&
            <ListItem button key={'send-location'} onClick={props.onSendLocation}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary={ProductCopy.shareLocation.primaryCta} />
            </ListItem>
            }
            { grantedPermissions.canReadLocation &&
            <ListItem button key={'stop-sharing-location'} onClick={props.onStopSharing}>
              <ListItemIcon>
                <VisibilityOffIcon />
              </ListItemIcon>
              <ListItemText primary='Stop Sharing location' />
            </ListItem>
            }
            { !permissions.sendLocation &&
            <ListItem button key={'invite-back'} onClick={props.onSendInvite}>
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText primary='Invite Back' />
            </ListItem>
            }
            {permissions.removeMember &&
            <ListItem button key={'remove'} onClick={props.onRemove}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary='Remove' />
            </ListItem>
            }
          </List>
        </Collapse>

        <Divider light />
      </div>

    )
  }
}

export default withOptimizely(SafetyNetworkMemberRow)
